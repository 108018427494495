import './styles.css';

import React, { useState, useEffect, useRef } from 'react';

import cx from 'classnames';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import possibleAnswers from './possibleAnswers';
import validGuesses from './validGuesses';
import ResponsiveGrid from 'components/ResponsiveGrid';
import GameOverMessage from './components/GameOverMessage';

export const NUM_ROWS = 6;
export const NUM_COLS = 5;

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return isDesktop;
};

/**
 * WordOllie is a Wordle clone, written by Ollie 😁.
 */
const WordOllie = () => {
  const [guess, setGuess] = useState('');
  const inputRef = useRef(null);
  const isDesktop = useIsDesktop();
  const guesses = getGuessesFromLocalStorage();
  const targetWord = getTargetWord();

  // Auto-focus the hidden input on desktops.
  useEffect(() => {
    if (isDesktop && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDesktop]);

  const onKeyReleased = key => {
    if (isGameOver(guesses, targetWord)) {
      return;
    }

    if (key === '⬅' || key === 'BACKSPACE') {
      setGuess(guess.slice(0, guess.length - 1));
      return;
    }

    if (key === 'SUBMIT' || key === 'ENTER') {
      if (isValidGuess(guess, targetWord)) {
        setGuessesToLocalStorage([...guesses, guess]);
        setGuess('');
      }
      return;
    }

    if (key.length > 1 || guess.length === targetWord.length) {
      return;
    }

    setGuess(guess + key);
  };

  return (
    <div className="WordOllie">
      <div className="WordOllie__grid-container">
        <ResponsiveGrid
          numRows={NUM_ROWS}
          numCols={NUM_COLS}
          gridStyles={{
            display: 'grid',
            gap: '2px',
            gridTemplateColumns: `repeat(${NUM_COLS}, 1fr)`,
            gridTemplateRows: `repeat(${NUM_ROWS}, 1fr)`,
          }}
          cellRenderer={({ row: r, col: c }) => (
            <div
              key={`${r} ${c}`}
              className={cx({
                WordOllie__cell: true,
                WordOllie__absent: isLetterAbsent(guesses[r], c, targetWord),
                WordOllie__present: isLetterPresent(guesses[r], c, targetWord),
                WordOllie__correct: isLetterCorrect(guesses[r], c, targetWord),
                WordOllie__invalid:
                  guesses.length === r &&
                  guess.length === 5 &&
                  !isValidGuess(guess),
              })}
            >
              {r < guesses.length
                ? guesses[r][c]
                : r === guesses.length && c < guess.length
                ? guess[c]
                : ''}
            </div>
          )}
        />
      </div>

      {isGameOver(guesses, targetWord) ? (
        <GameOverMessage
          guesses={guesses}
          targetWord={targetWord}
          stats={getStats()}
        />
      ) : (
        <div className="WordOllie__keyboard-container">
          {isDesktop && (
            <>
              <div className="WordOllie__legend">
                <div
                  className={cx('WordOllie__legend-cell', 'WordOllie__absent')}
                >
                  Absent
                </div>
                <div
                  className={cx('WordOllie__legend-cell', 'WordOllie__present')}
                >
                  Present
                </div>
                <div
                  className={cx('WordOllie__legend-cell', 'WordOllie__correct')}
                >
                  Correct
                </div>
              </div>
              <input
                ref={inputRef}
                type="text"
                value={guess}
                onChange={e =>
                  setGuess(
                    e.target.value
                      .toUpperCase()
                      .replace(/[^A-Z]/g, '')
                      .slice(0, 5)
                  )
                }
                onKeyUp={e => {
                  const key = e.key.toUpperCase();
                  if (key === 'ENTER') {
                    onKeyReleased(key);
                  }
                }}
                maxLength={5}
              />
            </>
          )}
          <Keyboard
            onKeyReleased={onKeyReleased}
            layout={LAYOUT}
            theme="hg-theme-default WordOllie__keyboard-theme"
            buttonTheme={getButtonThemes(guess, guesses, getTargetWord())}
          />
        </div>
      )}
    </div>
  );
};

const getLocalStorageKey = () => getTargetWord();

const getGuessesFromLocalStorage = () => {
  const todaysGuesses = window.localStorage.getItem(getLocalStorageKey());
  if (todaysGuesses === null) {
    return [];
  }

  return todaysGuesses.split(',');
};

const getStats = () => {
  const keys = Object.keys(window.localStorage);
  let numWins = 0;

  for (const key of keys) {
    const guesses = window.localStorage.getItem(key).split(',');
    if (key === guesses[guesses.length - 1]) {
      numWins++;
    }
  }

  return {
    numWins,
    numLosses: keys.length - numWins,
  };
};

const setGuessesToLocalStorage = guesses => {
  window.localStorage.setItem(getLocalStorageKey(), guesses.join(','));
};

export const isWin = (guesses, targetWord) =>
  guesses[guesses.length - 1] === targetWord;

export const isLetterAbsent = (guess, i, targetWord) =>
  guess &&
  !isLetterPresent(guess, i, targetWord) &&
  !isLetterCorrect(guess, i, targetWord);

export const isLetterPresent = (guess, index, targetWord) => {
  if (!guess) {
    return false;
  }

  const guessLetter = guess[index];

  // Remove exact matches
  const guessArr = guess.split('');
  const targetWordArr = targetWord.split('');

  for (let i = 0; i < targetWord.length; i++) {
    if (targetWord[i] === guess[i]) {
      guessArr[i] = null;
      targetWordArr[i] = null;
    }
  }

  // Count number of this letter in the targetWord (excluding exact matches)
  let numTargetOccurrences = 0;
  for (const targetLetter of targetWordArr) {
    if (targetLetter === guessLetter) {
      numTargetOccurrences++;
    }
  }

  // Count number of occurrences of the guess letter
  let numPriorGuessOccurrences = 0;
  for (let i = 0; i < index; i++) {
    if (guessArr[i] === guessLetter) {
      numPriorGuessOccurrences++;
    }
  }

  if (numPriorGuessOccurrences >= numTargetOccurrences) {
    return false;
  }

  return targetWordArr.includes(guessLetter);
};

export const isLetterCorrect = (guess, i, targetWord) =>
  guess && guess[i] === targetWord[i];

const isGameOver = (guesses, targetWord) =>
  guesses.length === 6 || guesses.includes(targetWord);

const getTargetWord = () => {
  const msInADay = 24 * 60 * 60 * 1000;
  const today = Date.now();
  const firstDay = new Date(2021, 5, 19, 0, 0, 0, 0).getTime();
  const index = Math.floor((today - firstDay) / msInADay);
  return possibleAnswers[index];
};
const isValidGuess = guess => validGuesses.has(guess);

const getButtonThemes = (guess, guesses, targetWord) => {
  const correctLetters = new Set();
  const presentLetters = new Set();
  const absentLetters = new Set();

  // Check for fully correct
  for (const guess of guesses) {
    for (let i = 0; i < targetWord.length; i++) {
      const guessLetter = guess[i];
      const targetLetter = targetWord[i];

      if (guessLetter === targetLetter) {
        correctLetters.add(guessLetter);
      }
    }
  }

  // Check for present
  for (const guess of guesses) {
    for (let i = 0; i < targetWord.length; i++) {
      const guessLetter = guess[i];
      if (
        !correctLetters.has(guessLetter) &&
        targetWord.includes(guessLetter)
      ) {
        presentLetters.add(guessLetter);
      }
    }
  }

  // The rest are absent
  for (const guess of guesses) {
    for (const guessLetter of guess.split('')) {
      if (
        !correctLetters.has(guessLetter) &&
        !presentLetters.has(guessLetter)
      ) {
        absentLetters.add(guessLetter);
      }
    }
  }

  const rv = [
    {
      class: isValidGuess(guess, targetWord)
        ? 'WordOllie__submit'
        : 'WordOllie__submit--disabled',
      buttons: 'SUBMIT',
    },
  ];

  if (absentLetters.size > 0) {
    rv.push({
      class: 'WordOllie__absent',
      buttons: [...absentLetters].join(' '),
    });
  }

  if (presentLetters.size > 0) {
    rv.push({
      class: 'WordOllie__present',
      buttons: [...presentLetters].join(' '),
    });
  }

  if (correctLetters.size > 0) {
    rv.push({
      class: 'WordOllie__correct',
      buttons: [...correctLetters].join(' '),
    });
  }

  return rv;
};

const LAYOUT = {
  default: [
    'SUBMIT',
    'Q W E R T Y U I O P',
    'A S D F G H J K L',
    'Z X C V B N M ⬅',
  ],
};

export default WordOllie;
