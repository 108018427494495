import './styles.css';

import React from 'react';
import { isLetterPresent, isLetterCorrect, isWin } from '../../';

const WIN_MESSAGES = [
  'Genius',
  'Magnificent',
  'Impressive',
  'Splendid',
  'Great',
  'Phew',
];

const getEmojiGrid = (guesses, targetWord) =>
  guesses
    .map(guess =>
      guess
        .split('')
        .map((_, i) =>
          isLetterCorrect(guess, i, targetWord)
            ? '🟩'
            : isLetterPresent(guess, i, targetWord)
            ? '🟨'
            : '⬛'
        )
        .join('')
    )
    .join('\n');

const shareResult = (guesses, targetWord) => {
  const date = new Date().toISOString().split('T')[0];
  const emojiGrid = getEmojiGrid(guesses, targetWord);
  const shareText = `WordOllie (${date})\n${emojiGrid}\n\nhttps://ollie-o.com/word-ollie`;
  navigator.clipboard.writeText(shareText);
  alert('Results copied to clipboard!');
};

const GameOverMessage = ({
  guesses,
  targetWord,
  stats: { numWins, numLosses },
}) => (
  <div className="GameOverMessage">
    <div className="GameOverMessage__inner">
      {isWin(guesses, targetWord)
        ? WIN_MESSAGES[guesses.length - 1] + '.'
        : `You lost. Word was ${targetWord}.`}{' '}
      {`Wins: ${numWins}, losses: ${numLosses}.`}
    </div>
    {isWin(guesses, targetWord) && (
      <button onClick={() => shareResult(guesses, targetWord)}>Share</button>
    )}
  </div>
);

export default GameOverMessage;
